import { ConfirmModal } from 'components/shared'
import { isMatchThreeHoursAgo } from 'components/shared/shared2'
import {
    endMatchETFirstHalf,
    endMatchETSecondHalf,
    endMatchFirstHalf,
    endMatchSecondHalf,
    hideMatchTimer,
    openAddEvent,
    startMatchETFirstHalf,
    startMatchETSecondHalf,
    startMatchFirstHalf,
    startMatchSecondHalf,
    updateFixture,
} from 'components/store'
import { useTimerMinute } from 'hooks'
import moment from 'moment'
import { Button, Modal } from 'react-bootstrap'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { endMatch, setMatchClock } from 'services'
import {
    canNotStartFirstHalf,
    canNotStartSecondHalf,
    getTimerHalf,
    getTimerMinute,
    isExtraTimeFirstHalf,
    isExtraTimeFirstHalfEnded,
    isExtraTimeSecondHalf,
    isExtraTimeSecondHalfEnded,
    isFirstHalf,
    isFirstHalfEnded,
    isSecondHalf,
    isSecondHalfEnded,
    isTimerStarted,
    toLowerCase,
} from 'utils'
import TimerComponent from './TimerComponent'
import { isMatchEnded } from 'components/shared/shared2'

const getHalfStartEndText = (fixture) => {
    const now = moment.utc().format()
    const { timer } = fixture
    if (isMatchEnded(fixture)) return 'Match Ended'
    if (isExtraTimeSecondHalfEnded(timer)) return 'Match Ended'
    if (isExtraTimeSecondHalf(timer) && getTimerMinute(timer, now, true) > 130) return 'Match Ended'
    if (isExtraTimeSecondHalf(timer)) return 'End ET Second Half'
    if (isExtraTimeFirstHalfEnded(timer)) return 'Start ET Second Half'
    if (isExtraTimeFirstHalf(timer) && getTimerMinute(timer, now, true) > 115) return 'Start ET Second Half'
    if (isExtraTimeFirstHalf(timer)) return 'End ET First Half'
    if (isSecondHalfEnded(timer)) return 'Start ET First Half'
    if (isSecondHalf(timer) && getTimerMinute(timer, now, true) > 115) return 'Start ET First Half'
    if (isSecondHalf(timer)) return 'End Second Half'
    if (isFirstHalfEnded(timer)) return 'Start Second Half'
    if (isFirstHalf(timer) && getTimerMinute(timer, now, true) > 70) return 'Start Second Half'
    if (isFirstHalf(timer)) return 'End First Half'
    return 'Start Match'
}

const getHalfStartEndType = (fixture) => {
    const now = moment.utc().format()
    const { timer } = fixture
    if (isMatchEnded(fixture)) return 'danger'
    if (isExtraTimeSecondHalfEnded(timer)) return 'danger'
    if (isExtraTimeSecondHalf(timer) && getTimerMinute(timer, now, true) > 130) return 'danger'
    if (isExtraTimeSecondHalf(timer)) return 'danger'
    if (isExtraTimeFirstHalfEnded(timer)) return 'success'
    if (isExtraTimeFirstHalf(timer) && getTimerMinute(timer, now, true) > 115) return 'success'
    if (isExtraTimeFirstHalf(timer)) return 'danger'
    if (isSecondHalfEnded(timer)) return 'success'
    if (isSecondHalf(timer) && getTimerMinute(timer, now, true) > 115) return 'success'
    if (isSecondHalf(timer)) return 'danger'
    if (isFirstHalfEnded(timer)) return 'success'
    if (isFirstHalf(timer) && getTimerMinute(timer, now, true) > 70) return 'success'
    if (isFirstHalf(timer)) return 'danger'
    if (!isTimerStarted(timer)) return 'success'
    return 'danger'
}

const getDurationInMinutes = (startTime, endTime) => {
    const start = moment(startTime)
    const end = moment(endTime)
    const duration = moment.duration(end.diff(start))
    return duration.asMinutes()
}

const isHalfButtonDisabled = timer => {
    const { first_half_start_time, second_half_start_time, et_first_half_start_time, et_second_half_start_time } = timer

    const halfTime = 45
    const extraTime = 15

    if (isFirstHalf(timer) && getDurationInMinutes(first_half_start_time, moment.utc()) < halfTime) return true

    if (isSecondHalf(timer) && getDurationInMinutes(second_half_start_time, moment.utc()) < halfTime) return true

    if (isExtraTimeFirstHalf(timer) && getDurationInMinutes(et_first_half_start_time, moment.utc()) < extraTime)
        return true

    if (isExtraTimeSecondHalf(timer) && getDurationInMinutes(et_second_half_start_time, moment.utc()) < extraTime)
        return true

    return false
}

const TimerHalfStartEndButton = ({ fixture, handleHalfStartEnd }) => {
    const { timer } = fixture
    // Keep this timerMinute here, otherwise the button will not be disabled when the timer is ended
    const timerMinute = useTimerMinute(timer)
    const halfStartEndText = getHalfStartEndText(fixture)
    const halfButtonDisabled = isMatchEnded(fixture) || isHalfButtonDisabled(timer)
    const halfStartEndType = getHalfStartEndType(fixture, timer)

    if (canNotStartSecondHalf(fixture) || canNotStartFirstHalf(fixture)) return null
    return (
        <ConfirmModal
            title={halfStartEndText}
            body={<p>Are you sure you want to ${toLowerCase(halfStartEndText)}?</p>}
            onConfirm={handleHalfStartEnd(fixture)}
            confirmText={halfStartEndText}
            confirmVariant={halfStartEndType}
            disabled={halfButtonDisabled}>
            <Button variant={halfStartEndType} size="lg" type="button" disabled={halfButtonDisabled}>
                {halfStartEndText}
            </Button>
        </ConfirmModal>
    )
}

const MatchTimer = () => {
    const dispatch = useDispatch()
    const { data: fixture, squadSlug, showTimer } = useSelector(state => state.fixture)
    const { id: matchId, timer } = fixture

    const handleClose = () => dispatch(hideMatchTimer())

    const handleShowAddEvent = () => {
        handleClose()
        dispatch(openAddEvent())
    }

    const handleHalfStartEnd = fixture => () => {
        const { timer } = fixture
        const now = moment.utc().format()

        if (isExtraTimeSecondHalf(timer) && getTimerMinute(timer, now, true) > 130) {
            dispatch(endMatchETSecondHalf(now))
            return
        }
        if (isExtraTimeSecondHalf(timer)) {
            dispatch(endMatchETSecondHalf(now))
            setMatchClock(squadSlug, matchId, false, 4, now)
            return
        }
        if (isExtraTimeFirstHalfEnded(timer)) {
            dispatch(startMatchETSecondHalf(now))
            setMatchClock(squadSlug, matchId, true, 4, now)
            return
        }
        if (isExtraTimeFirstHalf(timer) && getTimerMinute(timer, now, true) > 115) {
            dispatch(endMatchETFirstHalf(now))
            dispatch(startMatchETSecondHalf(now))
            setMatchClock(squadSlug, matchId, true, 4, now)
            return
        }
        if (isExtraTimeFirstHalf(timer)) {
            dispatch(endMatchETFirstHalf(now))
            setMatchClock(squadSlug, matchId, false, 3, now)
            return
        }
        if (canNotStartSecondHalf(fixture)) {
            toast.dismiss()
            toast(
                'Sorry you took too long to start the timer for the second half, and you cannot use the timer now. By our calculations, the current half has already begun.'
            )
            return
        }
        if (isSecondHalfEnded(timer)) {
            dispatch(startMatchETFirstHalf(now))
            setMatchClock(squadSlug, matchId, true, 3, now)
            return
        }
        if (isSecondHalf(timer) && getTimerMinute(timer, now, true) > 115) {
            dispatch(endMatchSecondHalf(now))
            dispatch(startMatchETFirstHalf(now))
            setMatchClock(squadSlug, matchId, true, 3, now)
            return
        }
        if (isSecondHalf(timer)) {
            dispatch(endMatchSecondHalf(now))
            setMatchClock(squadSlug, matchId, false, 2, now)
            return
        }
        if (isFirstHalfEnded(timer)) {
            dispatch(startMatchSecondHalf(now))
            setMatchClock(squadSlug, matchId, true, 2, now)
            return
        }
        if (isFirstHalf(timer) && getTimerMinute(timer, now, true) > 70) {
            dispatch(endMatchFirstHalf(now))
            dispatch(startMatchSecondHalf(now))
            setMatchClock(squadSlug, matchId, true, 2, now)
            return
        }
        if (isFirstHalf(timer)) {
            dispatch(endMatchFirstHalf(now))
            setMatchClock(squadSlug, matchId, false, 1, now)
            return
        }
        if (canNotStartFirstHalf(fixture)) {
            toast.dismiss()
            toast(
                'Sorry you took too long to start the timer for the first half, and you cannot use the timer now. By our calculations, the current half has already begun.'
            )
            return
        }
        if (!isTimerStarted(timer)) {
            dispatch(startMatchFirstHalf(now))
            setMatchClock(squadSlug, matchId, true, 1, now)
            return
        }
    }

    const handleEndMatch = async () => {
        const response = await endMatch(squadSlug, matchId)
        if (response.status === 202 && response.data && response.data.status === 'success') {
            const {
                data: { data: match },
            } = response
            dispatch(updateFixture(matchId, match))
            handleClose()
        }
    }

    if (!showTimer) return null

    return (
        <Modal show={showTimer} onHide={handleClose} className="match-timer-modal fill-in">
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <div className="flex-center flex-column h-100">
                    <TimerComponent fixture={fixture} className="display-2 w-100" liveHeight={4} />
                    <span className="text-3xl mb-4">{getTimerHalf(timer)}</span>

                    <div className="d-flex gap-2 mb-2">
                        <Button variant="complete" size="lg" onClick={handleShowAddEvent}>
                            Add Event
                        </Button>
                        <TimerHalfStartEndButton fixture={fixture} handleHalfStartEnd={handleHalfStartEnd} />
                    </div>
                    {!isMatchEnded(fixture) && (isSecondHalfEnded(timer) || isMatchThreeHoursAgo(fixture)) && (
                        <div className="d-flex gap-2">
                            <ConfirmModal
                                title="End Match"
                                body={<p>Are you sure you want to end the match?</p>}
                                onConfirm={handleEndMatch}
                                confirmText="End Match"
                                confirmVariant="danger">
                                <Button variant="danger" size="lg" type="button">
                                    End Match
                                </Button>
                            </ConfirmModal>
                        </div>
                    )}
                    {canNotStartFirstHalf(fixture) && (
                        <p className="text-center">
                            Sorry you took too long to start the timer for the first half, and you cannot use the timer
                            now.

                            {/* By our calculations, the current half has already begun. */}
                        </p>
                    )}

                    {canNotStartSecondHalf(fixture) && (
                        <p className="text-center">
                            Sorry you took too long to start the timer for the second half, and you cannot use the timer
                            now. 
                            {/* By our calculations, the current half has already begun. */}
                        </p>
                    )}
                </div>
            </Modal.Body>
        </Modal>
    )
}
export default MatchTimer

import classNames from 'classnames'
import { ConfirmModal, Icon } from 'components/shared'
import { getMatchStartingMinutesAgo, matchHasStarted, isMatchEnded } from 'components/shared/shared2'
import { showMatchTimer, startMatchFirstHalf } from 'components/store'
import { useTimerButton } from 'hooks'
import moment from 'moment'
import { Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { setMatchClock } from 'services'
import { canNotStartFirstHalf, getTimerHalf, isFullTime, isTimerStarted } from 'utils'
import TimerComponent from '../MatchTimer/TimerComponent'

const TimerButton = () => {
    const dispatch = useDispatch()
    const { isLoading, squadSlug, data: fixture } = useSelector(state => state.fixture)
    const { id: matchId, location, start_time, timer } = fixture

    const showTimer = () => {
        if (isMatchEnded(fixture)) return
        dispatch(showMatchTimer())
    }

    const onConfirm = () => {
        const time = moment.utc().format()
        dispatch(startMatchFirstHalf(time))
        setMatchClock(squadSlug, matchId, true, 1, time)
    }

    const onStartOriginalTime = () => {
        const { start_time } = fixture
        const startTime = moment.utc(start_time).format()
        dispatch(startMatchFirstHalf(startTime))
        setMatchClock(squadSlug, matchId, true, 1, startTime)
    }

    const isMatchStarted = matchHasStarted(fixture)

    const shouldRender = useTimerButton(fixture)

    if (isFullTime(fixture)) return null

    return (
        <>
            {isTimerStarted(timer) || canNotStartFirstHalf(fixture) ? (
                <Button
                    onClick={showTimer}
                    disabled={isLoading}
                    size="lg"
                    className={classNames({
                        'text-danger': isMatchEnded(fixture),
                    })}>
                    <TimerComponent fixture={fixture} className="text-2xl mt-0.5 mb-px" />
                    <span className="fixture-menu-text">{getTimerHalf(fixture)}</span>
                </Button>
            ) : (
                shouldRender && (
                    <ConfirmModal
                        title="Start Match Timer"
                        body={
                            isMatchStarted ? (
                                <p>
                                    Your match has technically already started, do you want to start the timer at
                                    original start time ({moment.utc(start_time).tz(location.timezone).format('h:mm A')}
                                    ) or now?
                                    <br />
                                    <br />
                                    <small>
                                        If you don&apos;t start the timer within the next{' '}
                                        <b>{15 - getMatchStartingMinutesAgo(fixture)}</b> minute(s), you won&apos;t be
                                        able to use it for the remainder of the match. This is done to keep the
                                        integrity of timekeeping of matches.
                                    </small>
                                </p>
                            ) : (
                                <p>Are you sure you want to start the match timer?</p>
                            )
                        }
                        onConfirm={onConfirm}
                        confirmText={isMatchStarted ? 'Start Now' : 'Start Match'}
                        thirdButtonText={isMatchStarted ? 'Start Original Time' : false}
                        onThirdButtonClick={onStartOriginalTime}>
                        <Button disabled={isLoading} size="lg">
                            <Icon name="record" size={30} className="text-danger bright" />
                            <span className="fixture-menu-text">{getTimerHalf(fixture)}</span>
                        </Button>
                    </ConfirmModal>
                )
            )}
        </>
    )
}

export default TimerButton

import { LINES } from '../constants'

const isFormation = formationList => {
    let formation = ''
    let count = 0
    let backsCount = 0

    if (!Array.isArray(formationList)) return ''
    else {
        Object.entries(LINES).forEach(([, positions]) => {
            count = 0
            positions.forEach(position => {
                let found = formationList.find(item => item === position)
                if (found) {
                    if (found === 'LWB' || found === 'RWB') {
                        backsCount++
                    } else {
                        count++
                    }
                }
            })
            if (count !== 0) formation += count
        })
        let reverse = ''

        for (var i = formation.length - 1; i >= 0; i--) {
            reverse += formation[i]
        }

        if (backsCount) {
            let num = parseInt(reverse.charAt(0)) + backsCount
            let sliced = reverse.slice(1, reverse.length)
            reverse = num + sliced
        }
        return reverse.split('').join('-')
    }
}
export default isFormation

import { Suspense, lazy } from 'react'

import { AdminLayout } from 'components/Layout'
import { useCurrentPath } from 'hooks'
import { useSelector } from 'react-redux'
import { Route, Routes } from 'react-router-dom'
import { anyRoute } from './LandingRoutes'

const Admin = lazy(() => import('components/FullPage/pages/Admin'))
const AdminTrainingModule = lazy(() => import('components/FullPage/pages/Admin/AdminTrainingModule'))
const AdminUsers = lazy(() => import('components/FullPage/pages/Admin/AdminUsers'))
const AdminSquads = lazy(() => import('components/FullPage/pages/Admin/AdminSquads'))
const AdminDrills = lazy(() => import('components/FullPage/pages/Admin/Drills'))

export const adminRoutes = [
    {
        path: '/admin',
        element: Admin,
    },
    {
        path: '/admin/training-module',
        element: AdminTrainingModule,
    },
    {
        path: '/admin/users',
        element: AdminUsers,
    },
    {
        path: '/admin/squads',
        element: AdminSquads,
    },
    {
        path: '/admin/drills',
        element: AdminDrills,
    },
    // {
    //     path: "/admin/coaches",
    //     element: AdminCoaches,
    // },
    // // {
    // //     path: "/admin/players",
    // //     element: AdminPlayers,
    // // },
    // {
    //     path: "/admin/groups",
    //     element: AdminGroups,
    // },
    // {
    //     path: "/admin/edit-user/:id",
    //     element: EditUser,
    // }
]
export const adminRoutesPath = adminRoutes.map(route => route.path)

const AdminRoutes = () => {
    const user = useSelector(state => state.user)
    const { is_admin: isAdmin } = user || { is_admin: false }
    const currentPath = useCurrentPath([...adminRoutes, ...anyRoute])
    const isAnyRoute = anyRoute.map(r => r.path).includes(currentPath)
    const isAdminRoute = adminRoutesPath.includes(currentPath)

    if (!isAdminRoute || !user || !isAdmin || isAnyRoute) return null

    return (
        <AdminLayout>
            <Suspense fallback={<div />}>
                <Routes>
                    {adminRoutes.map(({ path, element: Component }, index) => (
                        <Route path={path} element={<Component />} key={index} />
                    ))}
                </Routes>
            </Suspense>
        </AdminLayout>
    )
}

export default AdminRoutes

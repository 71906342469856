import { GooglePlacesComponent } from 'components/shared'
import DebouncingValidatingField from 'components/shared/DebouncingValidatingField'
import FinalFormError from 'components/shared/FinalFormError'
import MapContainer from 'components/shared/MapContainer'
import ReactSelectAdapter from 'components/shared/ReactSelectAdapter'
import { closeEditMatch, updateFixture, updateSelectedDayEvents, updateUpcomingEvent } from 'components/store'
import arrayMutators from 'final-form-arrays'
import { isEmpty } from 'lodash'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import { Form as BsForm, Button, Modal } from 'react-bootstrap'
import { SingleDatePicker } from 'react-dates'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { Field, Form } from 'react-final-form'
import { getLatLng } from 'react-google-places-autocomplete'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import {
    getAddressByGeocode,
    getGeocodeByAddress,
    getTimezoneByLatLng,
    updateMatchLocation,
    validateOverlapDates,
} from 'services'
import { getTimes, getAllSearchParams } from 'utils'

const EditMatchModal = ({ showModal, fixture }) => {
    const dispatch = useDispatch()
    const { squadSlug } = useSelector(state => state.fixture)
    const navigate = useNavigate()
    const {
        id,
        location: { address, lat, lng, city, country, timezone, region },
        date: fixtureDate,
        start_time: startTime,
    } = fixture
    const [searchParams, setSearchParams] = useSearchParams()
    const params = getAllSearchParams(searchParams)

    const squad = useSelector(state => state.clubSquadDetail)
    const times = getTimes()

    const [newLocationAddress, setNewLocationAddress] = useState(address)

    const [newLatLongData, setLatLongData] = useState({
        lat,
        lng,
    })
    const fixtureDateMoment = moment.utc(fixtureDate).tz(timezone)
    const [date, setDate] = useState(fixtureDateMoment)
    console.log(`🔥 | file: EditMatch.jsx:39 | date:`, date)

    const [focused, setFocused] = useState(false)
    const [startTimeDropdown, setStartTimeDropdown] = useState([])
    const [endTimeDropdown, setEndTimeDropdown] = useState([])

    const setStartTime = val => {
        setEndTimeDropdown(getTimes().filter(e => e.value !== '24:00' && e.value > val.value))
    }

    const handleClose = () => dispatch(closeEditMatch())

    const onSubmit = async (values, form) => {
        const {
            match_date: matchDate,
            start_time: { value: startTime },
            address,
            city,
            country,
            region,
            lat,
            lng,
            location_timezone: timezone,
        } = values

        const { data: responseData } = await updateMatchLocation(
            squadSlug,
            id,
            matchDate,
            startTime,
            60,
            address,
            city,
            country,
            region,
            lat,
            lng,
            timezone
        )
        const { data: match, status } = responseData

        if (status === 'success') {
            dispatch(updateFixture(match.id, match))
            const payload = {
                id: match.id,
                date: fixtureDate,
                type: 'matches',
                data: match,
            }
            dispatch(updateUpcomingEvent(payload))
            dispatch(updateSelectedDayEvents(payload))
            form.reset()
            handleClose()
            setSearchParams({ ...params, match: `${squadSlug}|${match.date}` })
        }
    }

    const placeOnChange = async (place, mutators) => {
        const geocode = await getGeocodeByAddress(place)
        const { formattedAddress, city, region, country } = await getAddressByGeocode(geocode)
        const { lat, lng } = await getLatLng(geocode)
        setNewLocationAddress(formattedAddress)
        mutators.setAddress(formattedAddress)
        mutators.setCity(city)
        mutators.setRegion(region)
        mutators.setCountry(country)
        mutators.setLatLongValue({ lat, lng })
        setLatLongData({ lat, lng })
        await getTimezoneByLatLng(lat, lng)
            .then(({ timeZoneId }) => {
                mutators.setTimezone(timeZoneId)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const onMapChange = async (latlng, mutators) => {
        const { lat, lng } = latlng.latlng
        const geocode = await getGeocodeByAddress(`${lat}, ${lng}`)
        const { formattedAddress, city, region, country } = await getAddressByGeocode(geocode)
        setNewLocationAddress(formattedAddress)
        mutators.setAddress(formattedAddress)
        mutators.setCity(city)
        mutators.setRegion(region)
        mutators.setCountry(country)
        mutators.setLatLongValue({ lat, lng })
        setLatLongData({ lat, lng })
        await getTimezoneByLatLng(lat, lng)
            .then(({ timeZoneId }) => {
                mutators.setTimezone(timeZoneId)
            })
            .catch(err => {
                console.log(err)
            })
    }

    // function that sets the next time making sure it falls within correct parameters
    // like if the location chosen in paris, time should reflect that
    const setTime = (date, timezone) => {
        let currentDate = moment().tz(timezone).format('YYYY-MM-DD')

        if (date.format('YYYY-MM-DD') === currentDate) {
            let currentTime = moment().tz(timezone).format('HH:mm:ss')
            setStartTimeDropdown(times.filter(e => e.value !== '24:00' && e.value > currentTime))
        } else {
            setStartTimeDropdown(times.filter(e => e.value !== '24:00'))
        }
    }

    useEffect(() => {
        setTime(fixtureDateMoment, timezone)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Modal
            show={showModal}
            onHide={handleClose}
            keyboard={false}
            centered
            className="edit-match-modal fill-in colorized-darkest">
            <Modal.Header closeButton>
                <Modal.Title className="text-left">
                    <h3 className="no-margin">Change Time & Location</h3>
                    <p className="m-t-0 m-b-0">Change your match's time and/or location</p>
                </Modal.Title>
            </Modal.Header>
            <Form
                onSubmit={onSubmit}
                initialValues={{
                    address,
                    city,
                    country,
                    lat,
                    lng,
                    region,
                    location_timezone: timezone,
                    start_time: {
                        label: moment.utc(startTime).tz(timezone).format('hh:mm a'),
                        value: moment.utc(startTime).tz(timezone).format('HH:mm'),
                    },
                    match_date: date ? date.format('YYYY-MM-DD') : fixtureDateMoment.format('YYYY-MM-DD'),
                }}
                mutators={{
                    ...arrayMutators,
                    setAddress: (args, state, utils) => {
                        utils.changeValue(state, 'address', () => args[0])
                    },
                    setCountry: (args, state, utils) => {
                        utils.changeValue(state, 'country', () => args[0])
                    },
                    setCity: (args, state, utils) => {
                        utils.changeValue(state, 'city', () => args[0])
                    },
                    setRegion: (args, state, utils) => {
                        utils.changeValue(state, 'region', () => args[0])
                    },
                    setCompetition: (args, state, utils) => {
                        utils.changeValue(state, 'competition', () => args[0])
                    },
                    setLatLongValue: (args, state, utils) => {
                        utils.changeValue(state, 'lat', () => args[0].lat)
                        utils.changeValue(state, 'lng', () => args[0].lng)
                    },
                    setTimezone: (args, state, utils) => {
                        utils.changeValue(state, 'location_timezone', () => args[0])
                    },
                    setLineup: (args, state, utils) => {
                        utils.changeValue(state, 'line_up', () => args[0])
                    },
                    setMatchDate: (args, state, utils) => {
                        utils.changeValue(state, 'match_date', () => args[0])
                    },
                }}
                render={({ handleSubmit, submitting, valid, values, form: { mutators } }) => (
                    <BsForm onSubmit={handleSubmit}>
                        <Modal.Body>
                            <hr className="m-t-0 m-b-10" />
                            {/* {console.log('🔥values', values)} */}
                            <BsForm.Group style={{ marginTop: 10 }}>
                                <BsForm.Label className="col-md-4">Choose Location</BsForm.Label>
                                <GooglePlacesComponent
                                    selectProps={{
                                        value: newLocationAddress
                                            ? {
                                                  label: newLocationAddress,
                                                  value: newLocationAddress,
                                              }
                                            : {
                                                  label: fixture.location.address,
                                                  value: fixture.location.address,
                                              },
                                        // placeholder: 'Search address',
                                        onChange: e => {
                                            // input.onChange(e.label);
                                            placeOnChange(e.label, mutators)
                                            mutators.setMatchDate(null)
                                            setDate(null)
                                        },
                                    }}
                                    placeholder="Search location"
                                />
                                <Field name="location">{({ meta }) => <FinalFormError meta={meta} notTouched />}</Field>
                                {!isEmpty(newLatLongData) && (
                                    <div className="m-t-5 m-b-15">
                                        <MapContainer
                                            className="px-0"
                                            height={300}
                                            onChange={latlng => onMapChange(latlng, mutators)}
                                            lat={newLatLongData.lat}
                                            lng={newLatLongData.lng}
                                            zoom={16}
                                        />
                                    </div>
                                )}
                            </BsForm.Group>
                            <BsForm.Group style={{ marginTop: 10 }}>
                                <BsForm.Label>Pick match date</BsForm.Label>
                                <DebouncingValidatingField
                                    name="match_date"
                                    validate={value => {
                                        return validateOverlapDates(value, squad, id)
                                    }}>
                                    {({ input, meta }) => (
                                        <div className="single-date-picker-wrapper">
                                            <SingleDatePicker
                                                {...input}
                                                onOutsideClick={true}
                                                numberOfMonths={1}
                                                focused={focused}
                                                className="form-control"
                                                date={date}
                                                readOnly={!values.address && true}
                                                // disabled={!values.address && true}
                                                onDateChange={value => {
                                                    if (value instanceof moment) {
                                                        setDate(value)
                                                        input.onChange(value.format('YYYY-MM-DD'))
                                                        setTime(value, values.location_timezone)
                                                    }
                                                }}
                                                onFocusChange={({ focused }) => {
                                                    setFocused(focused)
                                                }}
                                            />
                                            {console.log('date', meta)}
                                            <FinalFormError meta={meta} />
                                        </div>
                                    )}
                                </DebouncingValidatingField>
                            </BsForm.Group>
                            <BsForm.Group style={{ marginTop: 10 }}>
                                <BsForm.Label className="col-md-4">Exact kick off time</BsForm.Label>
                                <div className="d-flex flex-column">
                                    <Field name="start_time">
                                        {({ input, meta }) => (
                                            <>
                                                <ReactSelectAdapter
                                                    {...input}
                                                    onChange={val => {
                                                        setStartTime(val)
                                                        input.onChange(val)
                                                    }}
                                                    options={startTimeDropdown}
                                                    isDisabled={isEmpty(date) && true}
                                                />
                                                {values.location_timezone && (
                                                    <span className="text-warning block">
                                                        ** Your Match time is local to {values.city}
                                                    </span>
                                                )}
                                                <FinalFormError meta={meta} />
                                            </>
                                        )}
                                    </Field>
                                </div>
                            </BsForm.Group>
                            {/* <span className="help">({formatTimeZone(squad.club.timezone)})</span> */}
                        </Modal.Body>
                        <Modal.Footer className="d-grid grid-cols-2  py-3">
                            <Button
                                type="button"
                                size="lg"
                                disabled={submitting || !valid}
                                variant="danger"
                                onClick={handleClose}
                                className="">
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                size="lg"
                                disabled={submitting || !valid}
                                variant="complete"
                                className="">
                                Update
                            </Button>
                        </Modal.Footer>
                    </BsForm>
                )}
            />
        </Modal>
    )
}

const EditMatch = () => {
    const { editMatch: showModal, data: fixture } = useSelector(state => state.fixture)

    if (!showModal || isEmpty(fixture)) return null

    return <EditMatchModal showModal={showModal} fixture={fixture} />
}

export default EditMatch

import Avatar from 'components/shared/Avatar'
import { Dropdown } from 'react-bootstrap'
import { Tool } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { logout } from './../store/actions'

const UserDropdown = ({ clickHandler }) => {
    const dispatch = useDispatch()
    const user = useSelector(state => state.user)

    const handleLogout = () => {
        clickHandler()
        dispatch(logout())
    }

    if (!user) return null

    return (
        <div className="d-flex align-items-center mb-2 user-dropdown">
            <Dropdown align="top-end">
                <Dropdown.Toggle as={Link} variant="adl" id="dropdown-basic">
                    <Avatar width={30} height={30} src={user.photo_url_xs} alt={user.username} />
                    <div className="dropdown-toggle-text d-flex flex-column lh-1">
                        <span className="text-white text-ellipsis mw-135">{user.name}</span>
                        <span className="text-secondary small">@{user.username}</span>
                    </div>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <div className="px-3 py-2 d-flex align-items-center">
                        <Avatar width={40} height={40} src={user.photo_url_xs} alt={user.username} />
                        <h4 className="text-complete bold p-l-10">{user.username}</h4>
                    </div>
                    <hr className="my-0.5 mx-0" />
                    <Dropdown.Item
                        as={Link}
                        to="/dashboard/profile/settings"
                        push="true"
                        className="dropdown-item"
                        onClick={clickHandler}>
                        My Profile
                    </Dropdown.Item>
                    <Dropdown.Item
                        as={Link}
                        to="/dashboard/profile/attributes"
                        push="true"
                        className="dropdown-item"
                        onClick={clickHandler}>
                        My Attributes
                    </Dropdown.Item>
                    <Dropdown.Item
                        as={Link}
                        to="/dashboard/profile/positions"
                        push="true"
                        className="dropdown-item"
                        onClick={clickHandler}>
                        My Positions
                    </Dropdown.Item>
                    {user && user.is_admin && (
                        <>
                            <Dropdown.Item
                                as={Link}
                                to="/admin"
                                push="true"
                                className="dropdown-item text-complete"
                                onClick={clickHandler}>
                                Scouty Admin
                            </Dropdown.Item>
                        </>
                    )}
                    <Dropdown.Item as={Link} onClick={handleLogout} push="true" className="dropdown-item">
                        Logout
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

export default UserDropdown

import isEmpty from 'lodash/isEmpty'
import momentOriginal from 'moment'
import moment from 'moment-timezone'
import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

export const getTimeZoneName = timezone => moment.tz(timezone).zoneAbbr()

export const usePrevious = value => {
    const ref = useRef(value)
    useEffect(() => {
        ref.current = value
    }, [value])
    return ref.current
}

export const getSquadAgeRange = squad => {
    const { age_range } = squad
    const ages = age_range.split('-')
    let str = ages[0]
    if (ages[1] === 'all') {
        return (str += '+')
    }
    return age_range
}

export const getSquadGender = squad => {
    const { gender } = squad
    return capitalizeFirstLetter(gender)
}

export const getSquadSquadSize = squad => {
    const { max_players } = squad
    return `${max_players} a-side`
}

export const getSquadCountry = squad => {
    const { country } = squad
    return <CountryByCode countryCode={country} />
}

export const years = () => {
    const now = new Date().getUTCFullYear()
    return Array(now - (now - 80))
        .fill('')
        .map((v, idx) => now - idx)
}

export const getSquadCity = squad => {
    if (isEmpty(squad)) return ''
    const { city } = squad
    if (city) {
        const loc = city.split('|')

        return loc[1] ? loc[1] : loc[0]
    }

    return ''
}

export const splitLocation = location => {
    if (location) {
        const loc = location.split('|')

        return loc[1] ? loc[1] : loc[0]
    }

    return ''
}

export const CountryByCode = ({ countryCode }) => {
    const { countries } = useSelector(state => state.masterData)
    const country = countries?.find(e => e.value === countryCode)
    if (!isEmpty(country) && country.label) {
        return country.label
    }

    return null
}

export const NationalityByCode = ({ countryCode }) => {
    const { countries } = useSelector(state => state.masterData)
    const country = countries?.find(e => e.value === countryCode)
    if (!isEmpty(country) && country.nationality) {
        return country.nationality
    }

    return null
}

export const nationalityByCode = (countryCode, countries) => {
    const country = countries?.find(e => e.value === countryCode)
    if (!isEmpty(country) && country.nationality) {
        return country.nationality
    }

    return null
}
export const countryByCode = (countryCode, countries) => {
    const country = countries?.find(e => e.value === countryCode)
    if (!isEmpty(country) && country.label) {
        return country.label
    }

    return null
}

export const codeByCountry = (countryString, countries) => {
    const country = countries.find(e => e.label === countryString)
    if (!isEmpty(country) && country.value) {
        return country.value
    }

    // return 'invalid country'
}

export const explodeLocation = loc => {
    const location = loc.split('|')
    return loc ? (location[1] ? location[1] : location[0]) : null
}

// export const formatEventDateTZ = (event, format = 'dddd, MMMM Do YYYY') => {
//     var utc = moment.utc(event.start_time).toDate()
//     if (event.json_location.timezone) {
//         return moment(utc).tz(event.json_location.timezone).format(format)
//     }
//     return utc
// }

// export const formatEventTimeTZ = (dateTime, timezone) => {
//     var utc = moment.utc(dateTime).toDate()
//     if (timezone) {
//         return moment(utc).tz(timezone).format('h:mmA')
//     }
//     return utc
// }

// export const formatStartTimeTZ = event =>
//     moment.utc(event.start_time).tz(event.group_info.location.timezone).format('HH:mm')

export const formatEndTimeTZ = event =>
    moment.utc(event.end_time).tz(event.group_info.location.timezone).format('HH:mm')

export const eventPassed = start_time => {
    const now = moment.utc().format('YYYY-MM-DD HH:mm')
    // remember this must be in 24 hour always
    // otherwise this will happen
    // 12:20 am is > 02:30 am ? its really not but this comparison makes it yes because 12 > 2.
    // console.log(`${now} is greater than ${event.start_time} ? ${now > event.start_time}`)
    if (now > start_time) {
        return true
    }

    return false
}
export const squadNameTooLong = squad => (squad?.squad_name?.length < 20 ? squad.squad_name : squad.nickname)

export const getOrdinalNum = number => {
    let selector

    if (number <= 0) {
        selector = 4
    } else if ((number > 3 && number < 21) || number % 10 > 3) {
        selector = 0
    } else {
        selector = number % 10
    }

    return number + ['th', 'st', 'nd', 'rd', ''][selector]
}

export const eventTimeFromNow = event => {
    var stillUtc = moment.utc(event.start_time).toDate()
    return moment(stillUtc).local().fromNow()
}

export const squadHasManager = squad => {
    const { staff } = squad

    return staff?.find(e => e.staff.name === 'manager')
}

export const isPrivateAndHide = (group, user) => {
    if (!isEmpty(group) && group.is_private && !group.am_member) {
        return true
    }

    // if (!isEmpty(group) && group.is_private && !isEmpty(user)) {
    //     if (group.am_member) {
    //         return false;
    //     } else {
    //         return true;
    //     }
    // }

    return false
}

export const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1)

export const userProfileEmpty = ({ city, country, age, footed, height, weight, preferred_positions }) =>
    city
        ? //  || !country || !age || !footed || !height || !weight || !preferred_positions
          true
        : false

export const greeting = () => {
    const hour = moment().hour()

    if (hour > 16 || hour < 4) {
        return {
            sal: 'Good evening',
            lbl: 'evening',
        }
    }

    if (hour > 11) {
        return {
            sal: 'Good afternoon',
            lbl: 'afternoon',
        }
    }

    return {
        sal: 'Good morning',
        lbl: 'morning',
    }
}

export const matchIsMoreThanThreeHoursAway = match => {
    const { start_time, arrive_before } = match
    // get 3 hours before meet up time before match.
    const start3HoursBfore = moment
        .utc(start_time)
        .subtract(arrive_before, 'minutes')
        .subtract(10, 'minutes')
        .format('YYYY-MM-DD HH:mm:ss')
    // get current time
    const currentTime = moment.utc().format('YYYY-MM-DD HH:mm:ss')

    // check if current time is still below the 3 hours + meet up before to allow to cancel
    if (currentTime < start3HoursBfore) {
        return true
    }
    return false
}

export const isMatchFiveMinsAway = match => {
    const { start_time } = match
    const startTime = momentOriginal.utc(start_time)
    const now = momentOriginal.utc()
    const difference = momentOriginal.duration(startTime.diff(now))
    return difference.asMinutes() > 5
}

export const isMatchThreeHoursAgo = match => {
    const { start_time } = match
    const startTime = momentOriginal.utc(start_time)

    const now = momentOriginal.utc()

    const difference = momentOriginal.duration(now.diff(startTime))

    return difference.asHours() > 3
}

export const isMatchFifteenMinsAgo = match => {
    const { start_time } = match
    const startTime = momentOriginal.utc(start_time)
    const now = momentOriginal.utc()
    const difference = momentOriginal.duration(now.diff(startTime))
    return difference.asMinutes() > 15
}

export const isMatchFiveMinsLeft = match => {
    const { start_time } = match
    const startTime = momentOriginal.utc(start_time)
    const now = momentOriginal.utc()
    const difference = momentOriginal.duration(startTime.diff(now))
    return difference.asMinutes() < 5
}

export const isMatchFifteenMinsLeft = match => {
    const { start_time } = match
    const startTime = momentOriginal.utc(start_time)
    const now = momentOriginal.utc()
    const difference = momentOriginal.duration(startTime.diff(now))
    return difference.asMinutes() < 15
}

export const getMatchStartingMinutesAgo = match => {
    const { start_time } = match
    const matchTime = momentOriginal.utc(start_time)
    const now = momentOriginal.utc()
    const difference = momentOriginal.duration(now.diff(matchTime))
    return Math.floor(difference.asMinutes())
}

export const isMatchEnded = match => {
    const { match_ended } = match
    return Boolean(match_ended)
}

export const matchHasStarted = match => {
    const matchStarted = momentOriginal.utc(match.start_time)
    const currentTime = momentOriginal.utc()

    return currentTime.isAfter(matchStarted)
}

export const trainingHasStarted = time => {
    // get 3 hours before meet up time before match.
    const matchStarted = moment.utc(time).format('YYYY-MM-DD HH:mm:ss')
    // get current time
    const currentTime = moment.utc().format('YYYY-MM-DD HH:mm:ss')

    // check if current time is still below the 3 hours + meet up before to allow to cancel
    if (currentTime > matchStarted) {
        return true
    }
    return false
}

export const numberToWord = number => {
    const num =
        'zero one two three four five six seven eight nine ten eleven twelve thirteen fourteen fifteen sixteen seventeen eighteen nineteen'.split(
            ' '
        )
    const tens = 'twenty thirty forty fifty sixty seventy eighty ninety'.split(' ')

    const number2words = n => {
        if (n < 20) return num[n]
        var digit = n % 10
        if (n < 100) return tens[~~(n / 10) - 2] + (digit ? '-' + num[digit] : '')
        if (n < 1000) return num[~~(n / 100)] + ' hundred' + (n % 100 == 0 ? '' : ' and ' + number2words(n % 100))
        return number2words(~~(n / 1000)) + ' thousand' + (n % 1000 != 0 ? ' ' + number2words(n % 1000) : '')
    }

    return number2words(number)
}

export const amInLineUp = (match, user) => match.players.find(({ player }) => player.id === user.id)

export const sortPlayers = (types, player1, player2) => {
    const positionOrder = {}
    types.forEach((position, index) => {
        positionOrder[position] = index
    })

    return positionOrder[player1.position] - positionOrder[player2.position]
}
